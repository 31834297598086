import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonCertificate'


const listcertificateApi =(params)=>getAction(baseURL+"/list",params);

const addcertificateApi=(params)=>postAction(baseURL+"/add",params);

const editcertificateApi=(params)=>putAction(baseURL+"/edit",params);

const deletecertificateByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcertificateByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const createCertificateApi=(params)=>postAction(baseURL+"/createCertificate",params);

const getExperienceApi=(params)=>postAction(baseURL+"/getExperience",params);

export{
    selectcertificateByIdApi,
    deletecertificateByIdApi,
    editcertificateApi,
    addcertificateApi,
    listcertificateApi,
    createCertificateApi,
    getExperienceApi
}
