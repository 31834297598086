<template>
  <el-tabs  style="height: 100%;" type="border-card">
    <el-tab-pane label="商品列表">
      <el-table :data="dataColumnList">
        <el-table-column type="index" label="序号" align="center" width="100px">
          <!-- <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column label="归属系统" prop="productNames" />
        <el-table-column label="商品code" prop="code" />
        <el-table-column label="商品名称" prop="name" />
        <el-table-column label="商品介绍" prop="name" />
        <el-table-column label="商品类型" prop="serviceType" />
        <!-- <el-table-column label="原价" prop="price" /> -->
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.isExperience==1?false:true"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="getExperienceButton(scope.$index,scope.row)"
            >申请体验</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="售卖列表">
      系统暂不对外售卖,如果需使用请联系
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { getExperienceApi } from "@/api/system/certificate/certificate.js";
import { getExperienceDataListApi } from "@/api/system/product/product.js";
export default {
  data() {
    return {
      radio: null,
      dataColumnList: [],
      dataFrom: {}
    };
  },
  created() {
    this.experienceDataListButton();
  },
  methods: {
    experienceDataListButton() {
      getExperienceDataListApi({ attribute: 2 }).then(res => {
        this.dataColumnList = res.result;
      });
    },
    getExperienceButton(index, row) {
      if (row.userStatus == "2")
        return this.$message.success("已经拥有该商品了,请刷新页面");
      var parm = {
        uniqueCode: row.uniqueCode,
        commodityCode: row.commodityCode,
        commodityName: row.commodityName,
        productCode: row.productCode
      };
      getExperienceApi(parm).then(res => {
        //这里要进行调整下==即：获取之后刷新下列表数据
        // this.$message.success("申请成功请刷新列表");
        // this.experienceDataListButton();
        this.$router.go(0);
        window.location.reload();
      });
    }
  }
};
</script>
<style scoped>
</style>